<template>
  <div>
    <el-tabs v-model="activePage" @tab-click="pageChange" class="barWrap">
      <el-tab-pane label="货主" name="index">
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="委托代开服务商" name="owner">
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="代理商" name="agency">
        <el-table :data="tableData" :header-cell-style="{background:'#f0f0f0',color:'#666'}" v-loading="loading">
          <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LegalPersonIName" label="姓名" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="SignDatetime" label="签订日期" width="160" show-overflow-tooltip></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //当前激活哪个tabs页
      activePage: '',
      loading: false,
      tableData: []
    }
  },
  methods: {
    //tabs页面跳转
    pageChange(e) {
      if (e.name == 'agency') {
        this.activePage = 'agency'
      } else {
        this.$router.push(`/goodsOwner/serviceProviderCooperation/${e.name}`)
      }
    },
    //刷新页面时确定tabs的选中
    chooseTabsIndex() {
      if (this.$route.fullPath.indexOf('owner') != -1) {
        this.activePage = 'owner'
      } else if (this.$route.fullPath.indexOf('index') != -1) {
        this.activePage = 'index'
      }
    }
  },
  created() {
    //刷新页面时确定tabs的选中
    this.chooseTabsIndex();
  }
}
</script>

<style lang="scss" scoped>
.barWrap {
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  position: sticky;
  top: 84px;
  min-width: 1070px;
  height: calc(100vh - 108px);
}
</style>